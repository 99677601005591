import {QuestionsInterface, SubQuestions, Options} from "./index"
import {required, maxLength} from "../utils/validators"
import {VeterinarianInfo} from "../types"

export enum orgType {
  ASSISTANCE,
  SHELTER,
}

export interface ValidateSpeciality extends VeterinarianInfo {
  organization: Options
  nonProfit: Options
  staff: Options
  form501: File
}

export const SUB_QUESTION_NON_PROFIT: SubQuestions<Options>[] = [
  {
    EXPECTED_PARENT_VALUE: [Options.YES],
    QUESTIONS: [
      {
        QUESTION_TEXT: "Please attach your 501(c)(3) form",
        OPTIONS: [
          {
            label: "(Only PDF files with max size of 1mb)",
            name: "form501",
            id: "form501",
            dataTestId: "form501",
            type: "file",
            componentType: "FileUpload",
            // VALIDATION: [listNotEmpty('form501')],
          },
        ],
      },
    ],
  },
]

export const SUB_QUESTIONS_VET: SubQuestions<Options>[] = [
  {
    EXPECTED_PARENT_VALUE: [Options.YES],
    QUESTIONS: [
      {
        QUESTION_TEXT: "What is the name of the veterinarian?",
        OPTIONS: [
          {
            label: "Veterinarian First Name",
            name: "firstName",
            id: "firstName",
            dataTestId: "firstName",
            type: "text",
            componentType: "Input",
            VALIDATION: [required, maxLength('firstName', 50)],
          },
          {
            label: "Veterinarian Last Name and Degree",
            name: "lastName",
            id: "lastName",
            dataTestId: "lastName",
            type: "text",
            componentType: "Input",
            VALIDATION: [required, maxLength('lastName', 50)],
          },
        ],
      },
      {
        QUESTION_TEXT: "What is the veterinarian’s license number?",
        VALIDATION: [required],
        OPTIONS: [
          {
            label: "Veterinarian License Number",
            name: "vetLicense",
            id: "vetLicense",
            dataTestId: "vetLicense",
            type: "text",
            surfly_private: "true",
            componentType: "Input",
          },
        ],
      },
    ],
  },
]

export const SPECIALTY_QUESTIONS: QuestionsInterface<Options>[] = [
  {
    QUESTION_TEXT: "Which of the following best describes your organization?",
    VALIDATION: [required],

    OPTIONS: [
      {
        label: "Assistance Organization",
        helperText:
          "(Low-Cost Spay/Neuter Clinics, Etc.)",
        value: orgType.ASSISTANCE,
        name: "organization",
        id: "org-assistance",
        dataTestId: "org-assistance",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "Shelter or Humane Society",
        value: orgType.SHELTER,
        name: "organization",
        id: "org-shelter",
        dataTestId: "org-shelter",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
  {
    QUESTION_TEXT:
      "Is your business a non-profit organization with a 501(c)(3)?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Yes",
        helperText: "(Must Upload a Completed 501(c)(3) Form)",
        value: Options.YES,
        name: "nonProfit",
        id: "np-yes",
        dataTestId: "np-yes",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        value: Options.NO,
        name: "nonProfit",
        id: "np-no",
        dataTestId: "np-no",
        type: "radio",
        componentType: "Radio",
      },
    ],
    SUB_QUESTIONS: [...SUB_QUESTION_NON_PROFIT],
  },
  {
    QUESTION_TEXT:
      "Do you have a veterinarian on staff or a sponsor veterinarian?",
    QUESTION_TEXT_HELPER:
      "(Required to order Hill’s Prescription Diet pet foods)",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Yes",
        value: Options.YES,
        name: "staff",
        id: "staff-yes",
        dataTestId: "staff-yes",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        value: Options.NO,
        name: "staff",
        id: "staff-no",
        dataTestId: "staff-no",
        type: "radio",
        componentType: "Radio",
        helperText: "(You will not be able to order Hill’s Prescription Diet)",
      },
    ],
    SUB_QUESTIONS: [...SUB_QUESTIONS_VET],
  },
]
