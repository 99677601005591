import {MAINTENANCE_ERROR} from "../../constants"

const Maintenance = () => {
  return (
    <div className="fixed inset-0 z-20 flex flex-col items-center justify-center bg-white">
      <div>
        <img
          src={process.env.REACT_APP_URL + "/images/paw.png"}
          className="h-[72px] w-[72px]"
          alt="spinner"
        />
      </div>

      <div className="sm:w-[520px]">
        <h1 className="my-8 text-center text-[2.75rem] font-extrabold uppercase leading-10">
          {MAINTENANCE_ERROR.header}
        </h1>
        <p
          className="mb-8 text-center text-lg font-normal text-brand-accent-gray-light"
          dangerouslySetInnerHTML={{__html: MAINTENANCE_ERROR.message}}
        />
      </div>
    </div>
  )
}

export default Maintenance
