import {useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {Form} from "react-final-form"
import {WrapperContainer, Button} from "ui"
import Question from "../utils/Question"
import {SPECIALTY_QUESTIONS} from "../../constants/validate-specialty"
import {
  RoutePaths,
  FormButton,
  QuestionsInterface,
  Options,
} from "../../constants"
import {nacMachineContext} from "../../context"
import {NACEvents, NACStates} from "../../machine/nac-machine.types"

const ValidateSpecialty = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {state, send} = useContext(nacMachineContext) || {}

  const onSubmit = (values: {
    organization: string
    staff: string
    non_profit: string
  }) => {
    send(NACEvents.NEXT, {data: {...values}})
    navigate(RoutePaths.SELECT_TRANSACTION_TYPE)
  }

  type valuesType = {
    organization: string
    "non-profit": string
    staff: string
  }

  const renderSubQuestion = (
    question: QuestionsInterface<Options>,
    values: valuesType,
    index: number,
    fieldName: string,
  ) => {
    if (
      question?.SUB_QUESTIONS &&
      question?.SUB_QUESTIONS[0].EXPECTED_PARENT_VALUE.includes(
        parseInt(values[fieldName as keyof valuesType]),
      )
    ) {
      return (
        <>
          <Question
            questions={question?.SUB_QUESTIONS[0].QUESTIONS}
            renderSubQuestion={renderSubQuestion}
            values={values}
            subQuestion={true}
          />
        </>
      )
    }
  }

  useEffect(() => {
    if (state?.value !== NACStates.validateSpeciality) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <div className="flex justify-center">
      <div className="w-full sm:w-[520px]">
        <WrapperContainer>
          <Form
            initialValues={{
              organization: state.context.validateSpeciality?.organization,
              nonProfit: state.context.validateSpeciality?.nonProfit,
              form501: state.context.validateSpeciality?.form501,
              staff: state.context.validateSpeciality?.staff,
              firstName: state.context.validateSpeciality?.firstName,
              lastName: state.context.validateSpeciality?.lastName,
              vetLicense: state.context.validateSpeciality?.vetLicense,
            }}
            onSubmit={onSubmit}
            validateOnBlur={true}
            render={({handleSubmit, invalid, values}) => (
              <form onSubmit={handleSubmit}>
                <Question
                  questions={SPECIALTY_QUESTIONS}
                  renderSubQuestion={renderSubQuestion}
                  values={values}
                />
                <div className="flex justify-end gap-8">
                  <Button
                    variant="text"
                    onClick={() => {
                      send(NACEvents.BACK)
                      navigate(RoutePaths.ACCOUNT_NON_PROFIT)
                    }}
                  >
                    {FormButton.BACK}
                  </Button>
                  <Button
                    disabled={invalid}
                    variant="primary"
                    mode="filled"
                    type="submit"
                  >
                    {FormButton.CONTINUE}
                  </Button>
                </div>
              </form>
            )}
          />
        </WrapperContainer>
      </div>
    </div>
  )
}

export default ValidateSpecialty
