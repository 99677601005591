import { useEffect, useRef } from 'react';

export const startGAEvent = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
    "event": "sign_up",
    "action": "start",
    "user_type" : "",
    "form_type": "",
    "method": "AODOCS",
    "sign_up_step": "init",
    "click_text": "Continue",
    "click_url": location.pathname
    });
};

export const stepGAEvent = (user_type:string, sign_up_step:string, url:string, form_type:string) => {
  const lowerCaseURL = url.toLowerCase();
  if(sign_up_step.toLocaleLowerCase() !== 'init' && sign_up_step.toLocaleLowerCase() !== "thankyou" && !lowerCaseURL.includes("contacts?id=")){
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      "event": "sign_up",
      "action": "step",
      "user_type" : user_type,
      "form_type": form_type,
      "method": "AODOCS",
      "sign_up_step": sign_up_step,
      "click_text": "Continue",
      "click_url": url
    });
  }
};

export const trackFieldValidation = (fieldName:string, message:string) => {
    const sign_up_step = (window?.location?.hash || 'signup_form').replace('#/', '');

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        "event": "sign_up",
        "action": "error",
        "form_type": "CREATE_ACCOUNT",
        "method": "AODOCS",
        "sign_up_step": sign_up_step,
        "click_text": "Continue",
        "user_type": "",
        "error_type": "Field Validation Error",
        "error_msg": `${fieldName}: ${message}`,
        "click_url": window?.location?.href || ''
    });
};

export const trackFailedSubmision = (account_type:string, sign_up_step:string, message:string, form_type:string) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        "event": "sign_up",
        "action": "error",
        "user_type": account_type,
        "form_type": form_type,
        "method": "AODOCS",
        "sign_up_step": sign_up_step,
        "error_type": "Form Submission Error",
        "error_msg": message,
        "click_text": "Continue",
        "click_url": window?.location?.href || ''
    });
};

export const trackSuccessfulAccountCreation = (doc_id:string, account_type:string, form_type:string) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        "event": "sign_up",
        "action": "complete",
        "user_type": account_type,
        "form_id": doc_id,
        "form_type": form_type,
        "method": "AODOCS",
        "sign_up_step": "thank-you",
        "click_text": "Continue",
        "click_url": window?.location?.href || ''
    });
};

export const trackFormAbandonment = (lastField: string) => {
    const sign_up_step = (window?.location?.hash || 'signup_form').replace('#/', '');
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        "event": "sign_up",
        "action": "exit",
        "user_type": "",
        "form_field": lastField,
        "form_type": "",
        "method": "AODOCS",
        "sign_up_step": sign_up_step,
        "click_text": "Continue",
        "click_url": window?.location?.href || ''
    });
};

export const FormAbandonmentTracker = () => {
    const lastFieldRef = useRef<string>('');
    const handleFieldInteraction = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target && target.name) {
        lastFieldRef.current = target.name;
      }
    };
  
    useEffect(() => {
      const formElements = document.querySelectorAll('input, textarea, select');
      formElements.forEach((element) => {
        element.addEventListener('input', handleFieldInteraction);
      });
  
      const handleBeforeUnload = () => {
        trackFormAbandonment(lastFieldRef.current);
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        formElements.forEach((element) => {
          element.removeEventListener('input', handleFieldInteraction);
        });
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);
  
    return null;
};