import {required} from "../utils/validators"

export const MAX_CHAR_NAME = 50
export const MAX_CHAR_ADDRESS = 60
export const ZIP_CODE_LENGTH = 5
export const PHONE_NUMBER_LENGTH = 10
export const PAGE_HEADER = "CUSTOMER ACCOUNT PORTAL"

export interface SelectDropDownOptions {
  label: string
  value: string
}

export interface QuestionOptionsInterface {
  label: string
  helperText?: string
  helperClass?: string
  value?: number | boolean | string
  name: string
  id: string
  type: string
  componentType: string
  dataTestId?: string
  VALIDATION?: ValidationFunction[]
  options?: SelectDropDownOptions[]
  disabled?: boolean
  warningtext?: string
  format?: (value: unknown) => unknown
  defaultValue?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  surfly_private?: any
}

export interface QuestionsInterface<T> {
  QUESTION_TEXT: string
  QUESTION_TEXT_HELPER?: string
  BLOCK_HELPER_TEXT?: string
  OPTIONS: QuestionOptionsInterface[]
  SUB_QUESTIONS?: SubQuestions<T>[]
  VALIDATION?: ValidationFunction[]
  id?: number
}

export interface SubQuestions<T> {
  EXPECTED_PARENT_VALUE: T[]
  QUESTIONS: QuestionsInterface<T>[]
}

export const TITLE = {
  VETERINARY: "Vet, mobile vet",
  PET: "Pet",
  NON_PROFIT: "Non-profit, Specialty",
  MILITARY: "MILITARY",
  LAW_INFORCEMENT: "law enforcement",
  UNIVERSITY: "University",
  NON_PROFIT_VET: "Non-profit, Specialty (VET)",
  NON_PROFIT_PET: "Non-profit, Specialty (PET)",
}

export enum RoutePaths {
  SELECT_LOCATION = "/select-location",
  LANDING_PAGE = "/",
  ACCOUNT_TYPE = "/account",
  ACCOUNT_VETERINARY = "/account/veterinary",
  ACCOUNT_PET = "/account/pet",
  ACCOUNT_PET_ALLOWED = "/account/pet-allow",
  ACCOUNT_STORE_LOCATION = "/account/store-location",
  ACCOUNT_NON_PROFIT = "/account/non-profit",
  ACCOUNT_MILITARY = "/account/military",
  ACCOUNT_LAW_ENFORCEMENT = "/account/law",
  ACCOUNT_VET_TECH = "/account/vet-tech",
  SELECT_TRANSACTION_TYPE = "/select-transaction-type",
  BUISNESS_INFORMATION = "/business-information",
  VALIDATE_SPECIALTY = "/validate-specialty",
  FINANCIAL_INFO = "/financial-information",
  SHIP_TO_QUALIFIER = "/shipto-qualifier",
  PAYMENT_INFORMATION = "/payment-information",
  ADD_SHIPTO = "/shipto-add",
  ADDITIONAL_SHIPTO = "/shipto-additional",
  CONTACTS = "/contacts",
  HOW_MANY_SHIP_TO_QUESTION = "/how-many-ship-to",
  SHIP_TO_ACCOUNT = "/ship-to-account",
  DATE_SIGN = "/datesign",
  REVIEW = "/review",
  THANK_YOU = "/thank-you",
  ADDITIONAL_SHIPTO_TRANSFER = "/shipto-additional-transfer",
  UPDATE_ACCOUNT = "/update-account",
  SIGN = "/sign",
}

export const WHERE_TO_GO_LINK = "https://www.hillspet.com/where-to-buy"
export const LANDING = {
  callout:
    "Before you get started, here are some things you need to have ready",
  cta: "Get Started",
  caution:
    "<strong> IMPORTANT : </strong> This agreement must be reviewed and digitally signed by the owner/corporate officer or by an authorized signer for your buisness.",
  description: `This portal is for US business customers who intend to purchase and retail Hill’s pet food at 5 or fewer locations. If you have more than 5 ship-to locations, contact Hill’s New Accounts team at 1 (800) 354-4557 (select option 2 and then option 7) or via email at new_accounts_1@hillspet.com for assistance.`,
  itemsList: [
    {
      title: "Business Name & Establishment Date",
    },
    {
      title: "501(c)(3) Form",
      description: "(IF NON-PROFIT ORGANIZATION)",
    },
    {
      title: "Federal Tax ID Number",
      description:
        "(EMPLOYER IDENTIFICATION NUMBER OR OWNER'S SOCIAL SECURITY NUMBER)",
    },
    {
      title: "Appropriate Tax Forms & Documentation",
      description: "(IF TAX-EXEMPT)",
    },
    {
      title: "Delivery Information for up to 5 Locations",
      description: "(LOCATION, TIMES, CONTACT, ETC.)",
    },

    {
      title:
        "Name & Contact Information of Owner/Corporate Officer or Authorized Signer",
    },
    {
      title: "Veterinary Number",
      description: "(FOR STAFF OR SPONSOR VETERINARIAN)",
    },
  ],
  tip: `<strong class="text-white">Are you a pet parent wanting to purchase food for your own pet?</strong> Use our <a target="_blank" href="${WHERE_TO_GO_LINK}" class="underline text-white text-sm">Where to Buy</a> tool to find a retailer near you.`,
}

export interface SelectAccountType {
  label: string
  helperText?: string
  value: number
  name: string
  id: string
}

export enum AccountType {
  VETERINARY,
  MOBILE_VETERINARY,
  ONLINE_VERTERINARY,
  PET_STORE,
  NON_PROFIT,
  MILITARY,
  LAW_ENFORCEMENT,
  VET_TECH,
  HILLS_FOOD,
}

export type BlockedAccountItemsAlertType = {
  [key in AccountType]?: {
    title: string
    description: string
    labelConfirm?: string
  }
}

export const BLOCKED_ACCOUNT_ITEMS: AccountType[] = [
  AccountType.ONLINE_VERTERINARY,
  AccountType.HILLS_FOOD,
]

export const BLOCKED_ACCOUNT_ITEMS_ALERT: BlockedAccountItemsAlertType = {
  [AccountType.ONLINE_VERTERINARY]: {
    title: "We’re sorry!",
    description:
      "At this time, we aren’t accepting Online Vet Store or Online Pet Store customers. Please contact us at a later date, as we may in the future.",
  },
  [AccountType.HILLS_FOOD]: {
    title: "We’re sorry!",
    description:
      "This site isn’t currently able to create or modify Hill’s Food, Shelter & Love accounts. Visit our shelter portal for further assistance.",
    labelConfirm: "Continue to Portal",
  },
}

export const SELECT_ACCOUNT_ITEMS: Array<SelectAccountType> = [
  {
    label: "Veterinary Hospital/Clinic",
    helperText: "(Occupying a Non-Residential Building with Signage)",
    value: AccountType.VETERINARY,
    name: "accountType",
    id: "vet",
  },
  {
    label: "Mobile Vet",
    value: AccountType.MOBILE_VETERINARY,
    name: "accountType",
    id: "mvet",
  },
  {
    label: "Online Vet or Pet Store",
    value: AccountType.ONLINE_VERTERINARY,
    name: "accountType",
    id: "onlinevet",
  },
  {
    label: "Pet Store",
    helperText: "(Occupying a Non-Residential Building with Signage)",
    value: AccountType.PET_STORE,
    name: "accountType",
    id: "petstore",
  },
  {
    label: "City/County Shelter/Humane Society or Non-Profit with a 501(c)(3)",
    helperText: "(Occupying a Non-Residential Building with Signage)",
    value: AccountType.NON_PROFIT,
    name: "accountType",
    id: "nonprofit",
  },
  {
    label: "Military",
    value: AccountType.MILITARY,
    name: "accountType",
    id: "mililtary",
  },
  {
    label: "Law Enforcement",
    value: AccountType.LAW_ENFORCEMENT,
    name: "accountType",
    id: "law",
  },
  {
    label:
      "Veterinary Technology/Nursing School or College of Veterinary Medicine",
    value: AccountType.VET_TECH,
    name: "accountType",
    id: "vettech",
  },
  {
    label: "Hill’s Food, Shelter & Love Program",
    helperText: "(Exclusive Hill’s Pet Food Shelter Partnership)",
    value: AccountType.HILLS_FOOD,
    name: "accountType",
    id: "hillsfood",
  },
]

export enum LOCATION {
  USA,
  CANADA,
}

export const selectLocationQuestion = "Where are you located?"
export const emailUs = "Email Us"
export interface SelectLocationType {
  label: string
  value: number
}

export const LOCATION_ITEMS: Array<SelectLocationType> = [
  {
    label: "United States",
    value: LOCATION.USA,
  },
  {
    label: "Canada",
    value: LOCATION.CANADA,
  },
]

export enum FormButton {
  BACK = "Go Back",
  CONTINUE = "Continue",
  SAVE_AND_CONTINUE = "Save & Continue",
  REVIEW = "Review My Information",
  SIGN_CONTRACT = "View & Sign Contract",
  SUBMITTING = "Submitting...",
}

export const HILLSPET_EMAIL_CANADA = "Contact_Canada@hillspet.com"

export const BLOCKED_SELECT_LOCATION_ALERT = {
  title: "We’re sorry!",
  description: `This portal is for US business customers who intend to purchase and retail Hill’s pet food at 5 or fewer locations. If you have more than 5 ship-to locations, contact Hill’s New Accounts team at 1 (800) 354-4557 (select option 2 and then option 7) or via email at new_accounts_1@hillspet.com for assistance.`,
}

type ValidationFunction = (data: string) => string | undefined

export interface QuestionsInterface<T> {
  QUESTION_TEXT: string
  QUESTION_TEXT_HELPER?: string
  OPTIONS: QuestionOptionsInterface[]
  SUB_QUESTIONS?: SubQuestions<T>[]
  VALIDATION?: ValidationFunction[]
}

export enum Options {
  YES,
  NO,
}

export interface SubQuestions<T> {
  EXPECTED_PARENT_VALUE: T[]
  QUESTIONS: QuestionsInterface<T>[]
}

export const US_STATES: SelectDropDownOptions[] = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
]
export const UPDATE_QUESTIONS = [
  {
    QUESTION_TEXT: "Would you like to make another update?",
    VALIDATION: [required],
    OPTIONS: [
      {
        label: "Yes",
        value: Options.YES,
        name: "update",
        id: "update-yes",
        dataTestId: "update-yes",
        type: "radio",
        componentType: "Radio",
      },
      {
        label: "No",
        value: Options.NO,
        name: "update",
        id: "update-no",
        dataTestId: "update-no",
        type: "radio",
        componentType: "Radio",
      },
    ],
  },
]

export const ERROR = {
  header: "Something Went Wrong",
  message:
    "An error has occurred and we were unable to submit your form, successfully. Use the button below to try again.",
  cta: "Try Again",
}

export const SPINNER = {
  submitting: "Submitting your request...",
  processing: "Processing your information...",
}

export const SESSION_EXPIRED_ERROR = {
  header: "Session Expired",
  message: `Your session has expired and any unsaved information has been lost. <strong class="text-brand-accent-gray-light">Log in from the navigation menu and then reload the portal to start again.</strong>`,
}

export const NOT_AUTHENTICATED_ERROR = {
  header: "Well, woof.",
  message: `It looks like you’re trying to access a secure page without logging in. <strong class="text-brand-accent-gray-light">Create an account or log in from the navigation menu to proceed.</strong>`,
}
export const MAINTENANCE_ERROR = {
  header: "Down For Maintenance",
  message: `Sorry we're down for maintenance. <strong class="text-brand-accent-gray-light">We'll be back shortly.</strong>`,
}

export const PHONE_REGEX = /[ ()-]/g;
