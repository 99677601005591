import React, {useContext, useEffect} from "react"
import {Form} from "react-final-form"
import {useNavigate, useLocation} from "react-router-dom"
import {Button, WrapperContainer, Stepper} from "ui"
import {
  AccountType,
  FormButton,
  Options,
  QuestionsInterface,
  RoutePaths,
  UPDATE_QUESTIONS,
  PHONE_REGEX
} from "../../constants"
import {
  BUISNESS_INFORMATION_QUESTIONS,
  BusinessInfoQuestions,
  EIN_ERROR_MESSAGE,
  HEADER,
  LegalBusinessForm,
  SSN_ERROR_MESSAGE,
  TERMS,
} from "../../constants/buisness-information"
import {TransactionType} from "../../constants/select-transaction"
import {nacMachineContext} from "../../context"
import {NACEvents, NACStates} from "../../machine"
import {BusinessInformantion} from "../../types"
import {getUpdatedBusinessInfoQuestions} from "../../utils/businessinfo-utils"
import {formatDate, formatDateInYearFormat} from "../../utils/formatDate"
import Option from "../utils/Option"
import Question from "../utils/Question"

const BusinessInformation = () => {
  const {state, send} = useContext(nacMachineContext) || {}
  const isEditFlow = state?.history?.value === NACStates.review

  const navigate = useNavigate()
  const location = useLocation()

  const onSubmit = (values: BusinessInformantion) => {
    values.phoneNumber = values.phoneNumber.replace(PHONE_REGEX, "");
    if(values.businessPhoneNum){
      values.businessPhoneNum = values.businessPhoneNum.replace(PHONE_REGEX, "");
    }
    if(values.universityDetails){
      values.universityDetails.phoneNumber = values.universityDetails.phoneNumber.replace(PHONE_REGEX, "");
    }
    
    const updatedData = values.formationDate
      ? {
          ...values,
          businessDate: formatDate(values.businessDate),
          formationDate: formatDate(values?.formationDate),
          history: state.history.value,
        }
      : {
          ...values,
          businessDate: formatDate(values.businessDate),
          history: state.history.value,
        }

    send(NACEvents.NEXT, {
      data: updatedData,
    })

    const nextRoute = getNextRoute(
      state?.context?.selectedTransaction,
      isEditFlow,
      state?.context?.accountType,
      values?.update,
    )
    navigate(nextRoute)
  }

  const isTransferOwnership =
    state?.context?.selectedTransaction == TransactionType.TransferOwnership
  const isUpdateFlow =
    state?.context?.selectedTransaction ==
    TransactionType.UpdateCustomerAccountProfile

  const updatedQuestions = getUpdatedBusinessInfoQuestions(
    BUISNESS_INFORMATION_QUESTIONS,
    state?.context?.selectedTransaction,
    state?.context?.accountType,
    state?.context?.validateSpeciality?.nonProfit,
  )

  useEffect(() => {
    if (state?.value !== NACStates.enterBuisnessInformation) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <>
      <Stepper currentStep={1} totalSteps={5} />
      <h2 className="my-4 text-sm font-bold">{HEADER}</h2>
      <WrapperContainer classes="my-4 w-full sm:w-[520px]">
        <Form
          onSubmit={onSubmit}
          validateOnBlur={true}
          initialValues={{
            ...state?.context?.businessInformation,
            businessDate: formatDateInYearFormat(
              state?.context?.businessInformation?.businessDate,
            ),
            formationDate: formatDateInYearFormat(
              state?.context?.businessInformation?.formationDate,
            ),
          }}
          render={({handleSubmit, invalid, values, touched}) => {
            const disabled =
              invalid ||
              (Number(values.hasEIN) === Options.YES
                ? values.ein !== values.confirmEIN
                : values.ssn !== values.confirmSSN)

            return (
              <form onSubmit={handleSubmit}>
                <Question
                  questions={updatedQuestions}
                  renderSubQuestion={renderSubQuestion}
                  values={values}
                  touched={touched}
                />
                {[AccountType.MILITARY, AccountType.VET_TECH].includes(
                  Number(state?.context?.accountType),
                ) ? null : (
                  <div className="-mx-4 -mt-8">
                    <Option
                      option={
                        state?.context?.businessInformation?.terms === false
                          ? TERMS.option
                          : TERMS.optionDefault
                      }
                    />
                  </div>
                )}

                <div className="my-3">
                  {isUpdateFlow && (
                    <Question questions={UPDATE_QUESTIONS} values={values} />
                  )}
                </div>
                <div className="mt-14 flex justify-end gap-8">
                  <Button
                    variant="text"
                    onClick={() => {
                      send(NACEvents.BACK, {
                        data: {history: state?.history?.value},
                      })

                      const backRoute = isEditFlow
                        ? RoutePaths.REVIEW
                        : Number(state?.context?.selectedTransaction) ===
                          TransactionType.TransferOwnership
                        ? RoutePaths.SHIP_TO_QUALIFIER
                        : RoutePaths.SELECT_TRANSACTION_TYPE
                      navigate(backRoute)
                    }}
                  >
                    {FormButton.BACK}
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    mode="filled"
                    disabled={disabled}
                  >
                    {isTransferOwnership || isUpdateFlow || isEditFlow
                      ? FormButton.SAVE_AND_CONTINUE
                      : FormButton.CONTINUE}
                  </Button>
                </div>
              </form>
            )
          }}
        />
      </WrapperContainer>
    </>
  )
}
type valuesType = {
  fedtax: string
  legalBusinessForm: string
  ssn: string
  confirmSSN: string
  ein: string
  confirmEIN: string
}

const renderSubQuestion = (
  question: QuestionsInterface<LegalBusinessForm | Options>,
  values: valuesType,
  index: number,
  fieldName: string,
  touched?: {
    [key: string]: boolean
  },
) => {
  if (
    question?.SUB_QUESTIONS &&
    question?.SUB_QUESTIONS[0].EXPECTED_PARENT_VALUE.includes(
      parseInt(values[fieldName as keyof valuesType]),
    )
  ) {
    return (
      <>
        <Question
          questions={question?.SUB_QUESTIONS[0].QUESTIONS}
          renderSubQuestion={renderSubQuestion}
          values={values}
          subQuestion={true}
        />
        {question?.id ===
          BusinessInfoQuestions.BusinessFedTaxIdentificationNumber &&
        touched &&
        touched["confirmEIN"] &&
        values.ein !== values.confirmEIN ? (
          <span className="text-xs text-red-700">{EIN_ERROR_MESSAGE}</span>
        ) : (
          <></>
        )}
      </>
    )
  } else if (
    question?.SUB_QUESTIONS &&
    question?.SUB_QUESTIONS[1].EXPECTED_PARENT_VALUE.includes(
      parseInt(values[fieldName as keyof valuesType]),
    )
  ) {
    return (
      <>
        <Question
          questions={question?.SUB_QUESTIONS[1].QUESTIONS}
          renderSubQuestion={renderSubQuestion}
          values={values}
          subQuestion={true}
        />

        {touched &&
        touched["confirmSSN"] &&
        values.ssn !== values.confirmSSN ? (
          <span className="text-xs text-red-700">{SSN_ERROR_MESSAGE}</span>
        ) : (
          <></>
        )}
      </>
    )
  }
}

function getNextRoute(
  selectedTransaction: TransactionType,
  isEditFlow: boolean,
  accountType: AccountType,
  update?: Options,
) {
  const updateFlowNextRoute =
    update == Options.YES ? RoutePaths.UPDATE_ACCOUNT : RoutePaths.DATE_SIGN
  if (selectedTransaction == TransactionType.UpdateCustomerAccountProfile) {
    if (isEditFlow && update == Options.NO) {
      return RoutePaths.REVIEW
    }
    return updateFlowNextRoute
  } else if (isEditFlow) {
    return RoutePaths.REVIEW
  } else if (accountType == AccountType.VET_TECH) {
    return RoutePaths.ADD_SHIPTO
  }

  return RoutePaths.FINANCIAL_INFO
}

export default BusinessInformation
