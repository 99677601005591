import {useContext, useEffect} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {WrapperContainer, Button, Stepper} from "ui"
import {Form} from "react-final-form"
import Question from "../../utils/Question"
import {nacMachineContext} from "../../../context"
import {NACEvents, NACStates} from "../../../machine/nac-machine.types"
import {RoutePaths, FormButton, PHONE_REGEX} from "../../../constants"
import {
  NEW_SHIPTO_QUESTIONS,
  TRANSFER_OWNERSHIP_QUESTIONS,
} from "../../../constants/shipto/qualifier"
import {TransactionType} from "../../../constants/select-transaction"
import {formatDate, formatDateInYearFormat} from "../../../utils/formatDate"

const NewShipTo = () => {
  const {state, send} = useContext(nacMachineContext) || {}
  const navigate = useNavigate()
  const location = useLocation()

  const onSubmit = (values: {
    soldTo: string
    firstName: string
    lastName: string
    phone: string
    email: string
    transferDate: string
  }) => {
    values.phone = values.phone.replace(PHONE_REGEX, "");
    send(NACEvents.NEXT, {
      data: {...values, transferDate: formatDate(values.transferDate)},
    })
    if (
      Number(state?.context?.selectedTransaction) ===
      TransactionType.TransferOwnership
    ) {
      navigate(RoutePaths.BUISNESS_INFORMATION)
    } else if (
      Number(state?.context?.selectedTransaction) ===
      TransactionType.UpdateCustomerAccountProfile
    ) {
      navigate(RoutePaths.UPDATE_ACCOUNT)
    } else {
      navigate(RoutePaths.ADD_SHIPTO)
    }
  }

  const isTransferOwnership =
    Number(state?.context?.selectedTransaction) ===
    TransactionType.TransferOwnership

  useEffect(() => {
    if (state?.value !== NACStates.shipToQualifier) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])
  return (
    <>
      <div className="mb-4">
        <Stepper currentStep={1} totalSteps={5} />
      </div>
      <div className="flex justify-center">
        <div className="w-full sm:w-[520px]">
          <WrapperContainer>
            <Form
              initialValues={{
                ...state.context.shipToQualifier,
                transferDate: formatDateInYearFormat(
                  state.context.shipToQualifier?.transferDate,
                ),
              }}
              validateOnBlur={true}
              onSubmit={onSubmit}
              render={({handleSubmit, invalid, values}) => (
                <form onSubmit={handleSubmit}>
                  {isTransferOwnership ? 
                    <Question questions={NEW_SHIPTO_QUESTIONS.filter(value => value.OPTIONS[0].id !== 'soldTo')} values={values} /> :
                    <Question questions={NEW_SHIPTO_QUESTIONS.filter(value => value.OPTIONS[0].id !== 'soldToNotRequired')} values={values} />
                  }
                  {isTransferOwnership && (
                    <>
                      <Question
                        questions={TRANSFER_OWNERSHIP_QUESTIONS}
                        values={values}
                      />
                    </>
                  )}
                  <div className="flex justify-end gap-8">
                    <Button
                      variant="text"
                      onClick={() => {
                        send(NACEvents.BACK)
                        navigate(RoutePaths.SELECT_TRANSACTION_TYPE)
                      }}
                    >
                      {FormButton.BACK}
                    </Button>
                    <Button
                      disabled={invalid}
                      variant="primary"
                      mode="filled"
                      type="submit"
                    >
                      {FormButton.CONTINUE}
                    </Button>
                  </div>
                </form>
              )}
            />
          </WrapperContainer>
        </div>
      </div>
    </>
  )
}

export default NewShipTo
