import React, {useContext, useState, useEffect} from "react"
import {Form} from "react-final-form"
import {useNavigate, useLocation} from "react-router-dom"
import {Button, Chip, WrapperContainer} from "ui"
import {FormButton, Options, RoutePaths} from "../../../constants"
import {
  ADDITONAL_SHIP_TO_QUESTIONS,
  SHIP_TO_NUMBERS_MESSAGE,
} from "../../../constants/shipto/transfer-ship-to"
import {nacMachineContext} from "../../../context"
import {NACEvents, NACStates} from "../../../machine"
import {AddShipTo} from "../../../types"
import Question from "../../utils/Question"

const AdditionalShipTo = () => {
  const {state, send} = useContext(nacMachineContext) || {}
  const navigate = useNavigate()
  const location = useLocation()

  const shiptoAccounts: number[] = state?.context?.addShipTo.map(
    (shipto: AddShipTo) => shipto.shipToAccountNumber,
  )
  const [deletedAccountNumbers, setDeletedAccountNumbers] = useState<number[]>(
    [],
  )
  const [shipToLocations, setShipToLocations] =
    useState<number[]>(shiptoAccounts)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (values: any) => {
    send(NACEvents.NEXT, {
      data: {additional: values.additional, deletedAccountNumbers},
    })

    const nextRoute =
      values.additional == Options.YES
        ? RoutePaths.SHIP_TO_ACCOUNT
        : RoutePaths.DATE_SIGN
    navigate(nextRoute)
  }

  useEffect(() => {
    if (state?.value !== NACStates.transferShipToLocation) {
      send(NACEvents.INIT, {data: {route: location.pathname}})
    }
  }, [])

  return (
    <WrapperContainer classes="my-4 w-full sm:w-[520px]">
      <Form
        onSubmit={onSubmit}
        initialValues={{
          additional: state?.context?.additionalShiptoTransfer,
        }}
        validateOnBlur={true}
        render={({handleSubmit, invalid, values, touched}) => {
          return (
            <form onSubmit={handleSubmit}>
              {shipToLocations.length ? (
                <h2 className="my-4 px-3 font-bold">
                  {SHIP_TO_NUMBERS_MESSAGE}
                </h2>
              ) : (
                <></>
              )}
              <div className="mb-2 flex flex-wrap gap-1">
                {shipToLocations.length ? (
                  shipToLocations.map((shipToLocation, index) => {
                    return (
                      <Chip
                        key={index}
                        data={shipToLocation < 5 ? "Ship To " + (shipToLocation + 1) : shipToLocation}
                        handleDelete={() =>
                          setShipToLocations(
                            shipToLocations.filter(location => {
                              if (location === shipToLocation) {
                                setDeletedAccountNumbers([
                                  ...deletedAccountNumbers,
                                  shipToLocation,
                                ])
                              }
                              return location !== shipToLocation
                            }),
                          )
                        }
                      />
                    )
                  })
                ) : (
                  <></>
                )}
              </div>
              <Question
                questions={ADDITONAL_SHIP_TO_QUESTIONS}
                values={values}
                touched={touched}
              />
              <div className="mt-14 flex justify-end gap-8">
                <Button
                  variant="text"
                  onClick={() => {
                    send(NACEvents.BACK)
                    navigate(RoutePaths.CONTACTS)
                  }}
                >
                  {FormButton.BACK}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  mode="filled"
                  disabled={invalid}
                >
                  {FormButton.SAVE_AND_CONTINUE}
                </Button>
              </div>
            </form>
          )
        }}
      />
    </WrapperContainer>
  )
}

export default AdditionalShipTo
